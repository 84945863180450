import React from 'react';
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Link } from 'react-scroll';
import config from '../config/index.json';
import '../i18n';
import { useTranslation } from 'react-i18next';

interface Props {
    setInputValue: (value: string) => void;
}

function Contact({ setInputValue }: Props) {

    const handleClick = () => {
      setInputValue('Contatti');
    };

    const { images } = config;
    const { logo1, logo2, logo3, logo4, logo5, logo6 } = images;

    const { t } = useTranslation();

    useEffect(() => {
        AOS.init({ duration: 2000 });
      }, []);

    const handleLinkClick = () => {
        handleClick();
    };

    return (
        <section id='contatti' className='pb-16'>
            <div className='bg-ffblue pt-32 pb-32 text-center'>
                <div className='container px-8 md:px-0'>
                    <h2 data-aos="fade-up" className='text-white text-2xl md:text-3xl font-bold md:leading-normal pb-16 pt-0 md:pt-12 text-center max-w-[800px] mx-auto my-0 md:px-8'>{t('contact.title')}</h2>
                    <div data-aos="fade-up" className='grid grid-cols-3 md:grid-cols-6 gap-6'>
                        <a href="https://www.prestocafe.ch/" target='_blank'><img src={logo1} data-aos="fade-up" data-aos-delay="25" className='rounded-full' /></a>
                        <a href="https://www.schrankerl.at/" target='_blank'><img src={logo2} data-aos="fade-up" data-aos-delay="50" className='rounded-full' /></a>
                        <a href="https://www.evocagroup.com/it" target='_blank'><img src={logo3} data-aos="fade-up" data-aos-delay="75" className='rounded-full' /></a>
                        <a href="https://www.elior.it/" target='_blank'><img src={logo4} data-aos="fade-up" data-aos-delay="100" className='rounded-full' /></a>
                        <a href="https://www.icoltiintavola.it/" target='_blank'><img src={logo5} data-aos="fade-up" data-aos-delay="125" className='rounded-full' /></a>
                        <a href="https://foodymed.it/" target='_blank'><img src={logo6} data-aos="fade-up" data-aos-delay="125" className='rounded-full' /></a>
                    </div>
                    <div data-aos="fade-up" data-aos-delay="250" className='text-center text-white text-xl md:text-xl pt-16 md:max-w-[800px] mx-auto my-0 pb-12'>{t('contact.p1')}<br />{t('contact.p2')}</div>
                    <Link
                        to="form"
                        onClick={handleLinkClick}
                        smooth={true}
                        className='cta font-poppins text-ffblue bg-ffbluelight mx-auto my-0 px-7 py-3 text-lg rounded-full font-bold cursor-pointer transition hover:transition-all hover:shadow-md hover:shadow-white/30 hover:bg-white btnContattaci'>{t('contact.btn')}</Link>
                </div>
            </div>
        </section>
    )
}

export default Contact